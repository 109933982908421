<template>
  <VueFinalModal class="searchModal__wrapper" name="search" v-bind="$attrs" classes="modal-container"
    content-class="modal-content searchModal__content" container-class="" @opened="onAfterOpen"
    @before-open="onBeforeOpen">
    <div id="settingsModal" class="box-modal searchModal redesign">
      <button type="button" class="searchModal__close" @click.prevent="closeModal()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" fill="#D9D9D9"
            d="M4.46424 4.46447C4.07372 4.85499 4.07372 5.48816 4.46424 5.87868L6.58557 8L4.46424 10.1213C4.07372 10.5118 4.07372 11.145 4.46424 11.5355C4.85477 11.9261 5.48793 11.9261 5.87846 11.5355L7.99978 9.41421L10.1211 11.5355C10.5116 11.9261 11.1448 11.9261 11.5353 11.5355C11.9258 11.145 11.9258 10.5118 11.5353 10.1213L9.41399 8L11.5353 5.87868C11.9258 5.48816 11.9258 4.85499 11.5353 4.46447C11.1448 4.07394 10.5116 4.07394 10.1211 4.46447L7.99978 6.58579L5.87846 4.46447C5.48793 4.07394 4.85477 4.07394 4.46424 4.46447Z" />
        </svg>
      </button>
      <div class="title">{{ $t("header.search") }}</div>
      <div class="searchModal_wrap">
        <div class="searchModal-header">
          <label class="searchModal-header-input">
            <FontIcon icon="search" size="20" class="searchModal-header-input-icon" />
            <input v-model="filter.search" id="msearch" @input="onSearch" type="text"
              :placeholder="$t('page.casino.type.search')" />
          </label>
        </div>
        <div class="searchModal-body">
          <div class="slots-grid">
            <div v-if="!loading && !list.length" class="slots-grid-empty">
              {{ $t("page.casino.type.notfound") }}
            </div>
            <template v-if="loading && !list.length">
              <div v-for="i in 18" :key="`live-f-${i}`" class="slots-grid-item vue-skeletor"></div>
            </template>
            <CardSlot v-for="item in list" :key="`slot-i-${item.id}`" :item="item" />
          </div>
          <button v-if="list.length && meta.current_page < meta.last_page && !loading && filter.search.length > 2"
            class="slots-load" :disabled="loading" @click="onPaginate">
            {{ $t("page.casino.type.load") }}
            <FontIcon icon="arrow" size="16" />
          </button>
          <button v-if="loading" class="slots-load">
            {{ $t("page.casino.game.loading") }}
          </button>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal, $vfm } from "vue-final-modal";
const { $axios, $auth, $showToast } = useNuxtApp();
import { onUnmounted } from "vue";

const list = ref([]);

const providers = ref([]);
const category = ref("popular");
const showProviders = ref(false);
const meta = ref({
  current_page: 1,
  last_page: 3,
  per_page: 24,
});
const filter = ref({
  search: "",
  sort: "popular",
});
const {
  params: { type },
} = useRoute();

const loading = ref(false);

const onFetch = async (force = false) => {
  if (!force && (loading.value || filter.value.search.length < 3)) return;
  loading.value = true;
  const params = serializeBody({
    page: meta.value.current_page,
    limit: 24,
    ...filter.value,
  });

  if (force) {
    delete force.search;
  }

  await $axios
    .$get("games/slots/v2/list", {
      params,
    })
    .then(({ response }) => {
      const games = response?.games?.data;

      if (games) {
        if (meta.value.current_page === 1) {
          list.value = games;
        } else {
          list.value.push(...games);
        }
        meta.value = response.games.meta;
        if (!providers.value.length) {
          providers.value = response.providers;
        }
      }
    })
    .finally(() => {
      loading.value = false;
    });
};
let timeout = null;
const onSearch = () => {
  if (filter.value.search && filter.value.search < 2) return;

  clearTimeout(timeout);
  timeout = setTimeout(() => {
    meta.value.current_page = 1;
    onFetch();
  }, 300);
};
const onPaginate = async () => {
  if (meta.value.current_page >= meta.value.last_page) return;
  meta.value.current_page++;
  await onFetch();
};

const onBeforeOpen = () => {
  onFetch(true);
};
const closeModal = () => {
  list.value = [];
  filter.value.search = "";
  $vfm.hide("search");
};
function onAfterOpen() {
  const el = document.querySelector("#msearch");
  if (el) {
    el.focus();
  }
}
</script>

<style lang="scss">
.searchModal {
  border-radius: 0px !important;
  background: var(--primary-bg) !important;
  height: 100%;

  .title {
    color: #2c3034;
    margin-bottom: 28px !important;

    &::after {
      width: 68px !important;
    }
  }

  &__close {
    background: var(--primary-100);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 20px;
    right: 12px;
    transition: all 0.2s;

    &:hover {
      background: var(--primary-200);
    }
  }

  &__content {
    width: 100%;
    height: calc(100vh - 64px);
    max-height: 100vh !important;
  }

  &__wrapper {
    .modal-container {
      padding: 0 !important;
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 980px) {
    width: 100% !important;
    padding: 20px 12px !important;
  }

  &-header {
    padding-bottom: 16px;

    &-input {
      position: relative;

      input {
        border: 1.5px solid transparent;
        background: var(--primary-100);
        color: var(--primary-300);
        border-radius: 6px;
        height: 44px;
        width: 100%;
        max-width: 100%;
        padding: 12px 12px 12px 40px;
        text-align: left;
        color: var(--primary-800);
        font-weight: 500;
        font-size: 16px;

        &::placeholder {
          text-align: center;
          text-align: left;
          color: var(--primary-300);
        }

        &:focus {
          border-color: var(--primary-300);
        }
      }

      &-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        color: var(--primary-300);
      }
    }
  }

  &-body {
    overflow-y: auto;
    height: calc(100vh - 215px);

    padding-right: 8px;
    margin-right: -8px;
  }

  .slots {
    &-grid {
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      gap: 12.58px;

      @media screen and (max-width: 1680px) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }

      @media screen and (max-width: 1440px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      @media screen and (max-width: 1180px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @media screen and (max-width: 690px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      &-item {
        aspect-ratio: 204/136;
        border-radius: 8px;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        &:hover {
          .slots-grid-item-overlay {
            opacity: 1;
          }
        }

        &-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba($color: #2b2544, $alpha: 0.9);
          opacity: 0;
          z-index: 1;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-direction: column;
          transition: all 100ms linear;
        }

        &-provider {
          font-weight: 700;
          font-size: 10px;
          line-height: 12px;
          /* identical to box height */

          text-align: center;
          letter-spacing: 0.05em;
          text-transform: uppercase;

          color: #ffffff;

          opacity: 0.5;
        }

        &-btns {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: 10px;
        }

        &-btn {
          background: linear-gradient(90deg, #7449e6 0.06%, #69a4ff 98.49%);

          color: #ffffff;
          height: 52px;
          width: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          transition: all 0.2s linear;

          svg {
            width: auto;
            height: 40%;
            margin-left: 4px;

            path {
              fill: #fff;
            }
          }

          &:hover,
          &:active {
            filter: brightness(1.15);
          }

          &--demo {
            background: linear-gradient(45deg, #ffc658 0%, #f4a100 100%);

            svg {
              height: 30%;
              margin: 0;
            }
          }
        }

        &-title {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          text-align: center;

          color: #ffffff;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-empty {
        border-radius: 4px;
        padding: 20px;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        color: var(--primary-800);
        text-align: center;
        font-weight: 600;
        padding: 28px 12px;
        grid-column: 1 / -1;
      }
    }

    &-load {
      background: var(--primary-100);
      border-radius: 4px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      height: 40px;
      padding: 10px 15px;
      margin: 16px auto 0 auto;
      color: var(--primary-600);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-left: 10px;
      }
    }
  }
}

.theme__dark {
  .searchModal {
    .title {
      color: #f2f0ff;
    }
  }
}
</style>
